import { defineStore } from 'pinia'

import {useSSEStore } from '@/stores/ssestore.js'
import {useUserStore } from '@/stores/userstore.js'
import {useMessageStore } from '@/stores/messagestore.js'

import { Match } from '../assets/js/match.js'

var heartbeat_timeout = 60;

const app_server = import.meta.env.VITE_APP_SERVER;

export const useMatchStore = defineStore('match', {
    /* This store manages the join/accept cycle of matches (and match queue's)
     * and makes sure you cannot try to join multiple matches or tournaments
     * at the same time.
     */
    state: () => { return {
        matches: [],
        created_matches: [],
        join_requests: {},
        join_status: null,

        current_joinrequest: null,
        current_matchqueue: null,

        current_join_counter_interval_id: null,
        current_join_counter: null,
        current_matchqueue_info: {},

        matchqueue_info: {},
        matchqueue_queued: {},
    };},
    getters: {
    active_joinrequest(){
        return this.current_joinrequest;
    },
    youngest_match(){
        // TODO make this work 
        return this.created_matches[0];
    },

    oldest_joinrequest(){
        // TODO make this work 
        let oldest_joinrequest = null;

        for(let match of this.created_matches){
            const join_requests = this.join_requests[match.match_id] || [];
            for(let join_request of join_requests){
                join_request.match_id = match.match_id;
                return join_request;
            }
        }
        return null;
    },

    can_join_match(){
        /* If this is false we cannot join a matchqueue or match */
        return this.current_matchqueue == null && this.current_joinrequest == null;
    },

    can_join_matchqueue(){
        /* If this is false we cannot join a matchqueue or match */
        return this.can_join_match && this.youngest_match == null;
    },

    current_queue(){
        return this.current_matchqueue;
    },
    },
    actions:{

    setup(){
        this.current_joinrequest = localStorage.getItem("joinrequest"); 
        this.current_match = localStorage.getItem("current_match");
        this.current_matchqueue = localStorage.getItem("current_matchqueue");

        this.addListeners();
        this.get_current_join_requests()
        this.get_created_matches();
        this.get_matchqueue_info(this.current_matchqueue);

        if(this.current_matchqueue){
            this.queue_for_tournament(this.current_matchqueue);
        }
    },

    addListeners(){
        const sseStore = useSSEStore();
        const messageStore = useMessageStore();
        sseStore.connect();

        sseStore.addListener("join request", (data) => {
            // someone joined one of our matches 
            this.join_requests[data.match_id] = data.requests;
            messageStore.alertUser("New join request", 
                `${data.requests[0].username} is waiting for you.`, {
                showNotification: true,
                playSound: true
            });   
        });
        
        sseStore.addListener("join cancelled", (data) => {
            // someone joined one of our matches 
            console.log("Cancelled join request", data);
            if(data.match_id in this.join_requests){
                this.join_requests[data.match_id] = data.requests;
            }
            if(localStorage.getItem("joinrequest") == data.match_id){
                localStorage.removeItem("joinrequest");
                localStorage.removeItem("current_matchqueue");
                this.join_status = "ready";
                this.current_joinrequest = null;
                this.current_matchqueue = null;
                this.stop_join_counter();
            }
            
            if(localStorage.getItem("current_matchqueue") == data.tournament_id){
                this.join_status = "ready";
                this.current_matchqueue = null;
                this.stop_join_counter();
            }
        });
        
        sseStore.addListener("join accepted", (data) => {
            // someone joined one of our matches 
            console.log("Accepted", data);
            const match = new Match();
            match.from_json(data.match);
            match.set_token(data.player.token);
            match.set_player_color(data.player.color);
            
            localStorage.removeItem("joinrequest"); 
            localStorage.removeItem("current_matchqueue");

            this.current_joinrequest = null;
            this.current_matchqueue = null;
            this.stop_join_counter();
            this.join_status = "joined";
            
            localStorage.setItem("current_match", 
                JSON.stringify(match.to_json())
            );
            
            setTimeout(() => { 
                this.router.replace({name:"match", params:{match_id: match.match_id}});
            }, 2000);

            messageStore.alertUser("Match Joined", 
                "The match will start soon.", {showNotification: true}); 
        });

        sseStore.addListener("tournament join", (data) => {
            // someone joined one of our matches 
            console.log("Someone joined/left the tournament", data);
            this.matchqueue_queued[data.tournament_id] = data.users_queued;

            // messageStore.alertUser("User joined tournament", 
            //     `A user joined the ${extra_data.info.name} tournament`, {
            //     showNotification: true,
            //     playSound: true
            // });   
        });

    },

    async get_current_join_requests(){
        /*
        Checks if the current user did a join request
        */ 
        const response = await fetch(app_server + `/user/join/requests/`, {
            method: "GET",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        
        const request_data = await response.json(); 
        
        if(request_data.join_requests.length > 0){
            // If we have current join requests we need to check if the join is accepted
            const match_id = request_data.join_requests[0];
            localStorage.setItem("joinrequest", match_id);
            this.join_status = "joining"
            this.current_joinrequest = match_id;
        }
        return request_data.join_requests;
    },

    async join_request(match_id){
        const messageStore = useMessageStore();

        const data = {
            match_id: match_id,
        };
        
        const response = await fetch(app_server + `/match/${match_id}/join/request/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify(data), 
        });
        
        const request_data = await response.json(); 
        
        if(request_data.status == "error"){
            messageStore.alertUser("Error", request_data.message); 
            return;
        }    
        
        messageStore.alertUser("Join success", 
            "Join request success, waiting for accept", 5000); 
        localStorage.setItem("joinrequest", match_id);

        this.join_status = "joining";
        this.current_joinrequest = match_id;
        this.start_join_counter()
        return;
    },

    async cancel_join_request(match_id=null, user_id=null){
        const messageStore = useMessageStore();

        if(match_id == null){
            match_id = this.current_joinrequest;
        }

        console.log("Cancelling the join request", match_id, user_id);
        const data = {
            user_id: user_id,
        };
        
        const response = await fetch(app_server + `/match/${match_id}/join/request/cancel/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify(data), 
        });
        
        const request_data = await response.json(); 
        
        if(request_data.status == "error"){
            console.log("ERROR Joining", request_data.message);
        }    
        messageStore.alertUser("Join cancelled", "Join request cancelled", 5000); 

        if(user_id == null){ // We cancel the current join_request
            this.join_status = "ready"
            this.current_joinrequest = null;
            this.stop_join_counter();
            localStorage.removeItem("joinrequest");
        }
        
        return;
    },

    async join_accept(match_id, user_id){
        const messageStore = useMessageStore();

        const data = {
            match_id: match_id,
            user_id: user_id,
        };

        const response = await fetch(app_server + `/match/${match_id}/join/accept/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify(data), 
        });
        
        const request_data = await response.json(); 
        if(request_data.status == "success"){
            // We accepted the join request, now commit to the match and connect
            this.join_status = "ready";

            const match = new Match(match_id)
            match.from_json(request_data.match);
            match.set_token(request_data.player.token);
            match.set_player_color(request_data.player.color);
            
            localStorage.setItem("current_match", 
                JSON.stringify(match.to_json())
            );
            // Now connect to the match
            this.router.replace({name:"match", params:{match_id: match.match_id}});
            messageStore.alertUser("Match Starting", "User accepted, match starting soon", 5000);
        }
    },

    async join_reject(match_id, user_id){
        return cancel_join_request(match_id, user_id);
    },

    async get_created_matches(){
        const response = await fetch(app_server + "/match/created/", {
            method: "GET",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        }).then(response => {
            if (response.status >= 400 && response.status < 600) {
                return null;
            }
            return response;    
        });
        if(response === null){
            this.created_matches = [];
            return;
        }
        const data = await response.json();
        
        this.created_matches = data["matches"];
        this.join_requests = data["join_requests"];
    },

    async remove_created_match(match_id){
        const messageStore = useMessageStore();

        const data = {}
        const response = await fetch(app_server + `/match/${match_id}/remove/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify(data), 
        });
         
        // remove the match from the DOM
        this.created_matches = this.created_matches.filter(x => x.match_id != match_id);
        delete this.join_requests[match_id];

        const request_data = await response.json(); 
        
        if(request_data.status == "error"){
            console.error("error deleting match", request_data.message);
        }    
        
        messageStore.alertUser("Match deleted", "");

        return;
    },

    /* Tournament methods */
    async get_matchqueue_info(tournament_id){
        if(tournament_id == null){
            return;
        }
        var url = app_server + `/tournament/${tournament_id}/info/`;

        const response = await fetch(url,{
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        
        if(response.status == "error"){
            return;
        }
        
        const data = await response.json();
        this.current_matchqueue_info = data.info;
        this.matchqueue_info[tournament_id] = data.info;
    },

    async queue_for_tournament(tournament_id, heartbeats=30){
        if(tournament_id == null){
            return;
        }
        this.get_matchqueue_info(tournament_id);
        this.start_matchqueue_counter();
        await this.join_tournament(tournament_id);
        for(let i=0; i < heartbeats; i++){
            if(this.current_matchqueue == null){
                clearInterval(this.current_join_counter_interval_id);
                return;
            }
            await new Promise(r => setTimeout(r, heartbeat_timeout*1000));
            if(!await this.send_heartbeat()){
                break;
            }
        }
        console.log(`Join timed out after ${heartbeats} heartbeats`);
        if(this.current_matchqueue != null){
            this.cancel_tournament_join();
        }
    },

    async join_tournament(tournament_id){
        const response = await fetch(app_server + `/tournament/${tournament_id}/join/`, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        
        const data = await response.json(); 
        if(data.status == "error"){
            console.error(data.message);
            clearInterval(this.current_join_counter_interval_id);
            this.current_join_counter = null;
            this.join_status = "ready";
            return;
        }
        
        if(data.status == "success"){ 
            this.matchqueue_queued[tournament_id] = data.users_queued;
            this.join_status = "joining";

            this.current_matchqueue = tournament_id;
            localStorage.setItem("current_matchqueue", tournament_id);
            return;
        }
    },

    async send_heartbeat(){
        if(this.current_matchqueue == null || this.join_status != "joining"){
            console.error("Cannot send heartbeat to:", this.current_matchqueue);
            return false;
        }
        const url = `/tournament/${this.current_matchqueue}/heartbeat/`;
        const response = await fetch(app_server + url, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        const data = await response.json();
        if(data.status == "success"){
            this.matchqueue_queued[data.tournament_id] = data.users_queued;
            return true;
        }else{
            console.log("Heartbeat error, cancelling");
            await this.cancel_tournament_join();
            return false;
        }
    },

    async cancel_tournament_join(){
        const messageStore = useMessageStore();
        if(this.current_matchqueue == null){
            console.error("Tried cancelling a non-active matchqueue");
            return;
        }
        const response = await fetch(app_server + `/tournament/${this.current_matchqueue}/cancel/`, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        this.join_status = "cancelling";
    },

    start_join_counter(){
        document.onvisibilitychange = () => {
            console.log("VISIBILITY");
            if(window.hidden){
                alert("You are queuing, are you sure you want to leave?");
            }
        };

        if(this.current_join_counter_interval_id != null){
            return;
        }
        if(this.current_join_counter == null){
            this.current_join_counter = 60;
            this.current_join_start = Date.now();
        }
        this.current_join_counter_interval_id = setInterval(() => {
            this.current_join_counter = (60 - (Date.now() - this.current_join_start)/1000).toFixed(0);
            if(this.current_join_counter < 0){
                this.cancel_join_request();
                this.current_join_counter = 0;
                this.stop_join_counter();
            }
        }, 1000);
    },

    start_matchqueue_counter(){
        if(this.current_join_counter_interval_id != null){
            return;
        }
        if(this.current_join_counter == null){
            this.current_join_counter = 0;
            this.current_join_start = Date.now();
        }
        this.current_join_counter_interval_id = setInterval(() => {
            this.current_join_counter = ((Date.now() - this.current_join_start)/1000).toFixed(0);
        }, 1000);
    },

    stop_join_counter(){
        if(this.current_join_counter_interval_id != null){
            clearInterval(this.current_join_counter_interval_id);
            this.current_join_counter = null;
            this.current_join_counter_interval_id = null;
        }
    },

    get_users_queued(tournament_id){
        return this.matchqueue_queued[tournament_id] || 0;
    },
    }
});

