<script setup>

import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {SparklesIcon, UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import Header from './Header.vue'
import ToolTip from './Tooltip.vue'
import Avatar from './Avatar.vue'
import UserTag from './UserTag.vue'
import UserName from './UserName.vue'
import MatchList from './MatchList.vue'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

import { Line, Radar } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();


const chart_options = {
    responsive: true,
    maintainAspectRatio: false,
    scales:{
        x:{
            ticks:{
                display: false,
            },
        },
    },
    plugins:{
        legend: {display: false},
    },
};

const extra_data = reactive({
    rating_list: [],
    charts: {},
    elo_list: [],
    er_table: null, 
    user_info: {},
    user: {},
    logged_in_user: {},
    user_id: null,
    show_er: 0,
});

onMounted(async () => {
    extra_data.user_id = route.params.user_id;
    await userStore.loadUser();

    if(!extra_data.user_id){
        extra_data.user_id = userStore.info.user_id
    }
    retrieve_data();
});

watch(() => route.fullPath, async () => {
    extra_data.user_id = route.params.user_id;
    get_user_info();
});

async function retrieve_data(){
    await Promise.allSettled([
        get_user_info(),
        get_tournaments(),
        get_user_solves(),
        get_achievements(),
        get_insights(),
        get_er_table(),
    ]);

    extra_data.matches = await get_user_matches({match_length: [1, 100]});
    extra_data.coin_matches = await get_user_matches({match_length: [0, 0]});
    const [matches_charts, matches_labels] = get_match_charts(extra_data.matches);
    const [coin_charts, coin_labels] = get_match_charts(extra_data.coin_matches);
    const tournament_charts_labels = get_tournament_charts();
    const puzzle_charts_labels = get_puzzle_charts();
    
    extra_data.charts = {
        elo: [matches_charts.elo, matches_labels],
        rating: [matches_charts.rating, matches_labels],
        er: [matches_charts.er, matches_labels],
        coins: [coin_charts.coins, coin_labels],
        coin_er: [coin_charts.er, coin_labels],
        master_points: tournament_charts_labels,
        puzzle_rating: puzzle_charts_labels,
    };
}

async function get_user_info(){
    const response = await fetch(app_server + `/user/${extra_data.user_id}/info/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_info = await response.json();
    
    extra_data.user = user_info.user; 
    extra_data.user.date_joined = new Date(extra_data.user.date_joined);
    extra_data.user_info = user_info;
}

async function get_user_matches(options){
    const url = `/user/${extra_data.user_id}/matches/?${new URLSearchParams(options).toString()}`;
    const response = await fetch(app_server + url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_matches = await response.json();
    
    for(let match of user_matches.matches){
        match.score = JSON.parse(match.score);
    }
    
    return user_matches.matches;
}

async function get_user_solves(){
    const url = `/user/${extra_data.user_id}/solves/`;
    const response = await fetch(app_server + url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_data = await response.json();
    
    extra_data.solves = user_data.solves; 
    return user_data.solves;
}

async function get_er_table(){
    const url = `/user/${extra_data.user_id}/er_table/`;
    const response = await fetch(app_server + url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_data = await response.json();
    extra_data.er_table = user_data.er_table;
    
    return user_data.er_table;
}

async function get_tournaments(){
    const url = `/user/${extra_data.user_id}/tournaments/`;
    const response = await fetch(app_server + url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_data = await response.json();
    user_data.tournaments.reverse();
    extra_data.tournaments = user_data.tournaments;
    
    return user_data.tournaments;
}

async function get_achievements(){
    const url = `/user/${extra_data.user_id}/achievements/`;
    const response = await fetch(app_server + url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_data = await response.json();
    extra_data.achievements = user_data.achievements;
    
    return user_data.achievements;
}

async function get_insights(){
    const url = `/user/${extra_data.user_id}/insights/`;
    const response = await fetch(app_server + url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_data = await response.json();
    extra_data.insights = user_data.insights;
    
    return user_data.insights;
}

function get_tournament_charts(){
    const charts = {
        master_points: [],
    };
    const chart_labels = [];
    
    for(let tournament of extra_data.tournaments){
        charts["master_points"].push(tournament.master_points_cumulative);
        chart_labels.push(tournament.finish_time);
    }
    return [charts.master_points, chart_labels];
}

function get_puzzle_charts(){
    const chart = [];
    const chart_labels = [];
    
    var puzzle_rating = extra_data.user.puzzle_rating;
    for(let solve of extra_data.solves){
        chart.push(puzzle_rating);
        chart_labels.push(solve.solution_time);
        puzzle_rating -= solve.user_rating_diff;
    }
    chart.reverse();
    chart_labels.reverse();
    
    return [chart, chart_labels];
}

function get_match_charts(matches){
    const charts = {
        elo: [],
        rating: [],
        er: [],
        coins: [],
    };
    const chart_labels = [];
    
    for(let match of matches.toReversed()){
        let player = null;
        if(match.white.user_id == extra_data.user_id){
            player =  match.white;
        }else if(match.black.user_id == extra_data.user_id){
            player =  match.black;
        }else{
            continue;
        }
        
        if( player.new_elo ){
            charts["elo"].push(player.new_elo);
        }
        if( player.new_rating ){
            charts["rating"].push(player.new_rating);
        }
        if( player.new_coins ){
            charts["coins"].push(player.new_coins);
        }
        charts["er"].push(player.er);
        chart_labels.push(match.create_time);
    }
    return [charts, chart_labels];
}

function to_readable_date(d){
    const month_nr_to_name = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Sep", "Oct", "Nov", "Dec"];
    return `${month_nr_to_name[d.getMonth()]}, ${d.getFullYear()}`
}

function get_chart_data(chart_type){
    const style = getComputedStyle(document.body);
    const field_color = style.getPropertyValue('--field-color');

    if(!extra_data.charts[chart_type]){
        return;
    }

    var [chart, labels] = extra_data.charts[chart_type]
    
    labels = labels.map(x => new Date(x*1000).toLocaleString());

    const chart_data = {
        labels: labels,
        datasets: [
            {
                label: chart_type,
                data: chart,
                borderColor: field_color,
            },
        ],
    }

    return chart_data;
}

function get_er_table_cells(variable_to_show=0){
    if(extra_data.er_table == null){
        return [];
    }
    const er_table = extra_data.er_table;
    const cells = [];
    for(let [match_length, vars] of er_table){
        if(match_length == 0){
            cells.push("sparkle");
        }else if(Number.isInteger(match_length)){
            cells.push(match_length);
        }else if(Array.isArray(match_length)){
            var m = "";
            if(Number.isInteger(match_length[0])){
                m += match_length[0];
            }else{
                m += "<";
            }
            if(Number.isInteger(match_length[1])){
                m += match_length[1];
            }else{
                m = ">" + m;
            }
            
            if(m == "><"){
                m = "Any";
            }
            cells.push(m)
        }
        let variable = vars[variable_to_show % vars.length];
            
        if(variable == null || variable >= 100){
            cells.push("-");
        }else{
            const prefix = (variable_to_show == 1) ? "±" : "";
            if(variable > 10.0){
                cells.push(prefix + variable.toFixed(1));
            }else{
                cells.push(prefix + variable.toFixed(2));
            }
        }
    }
    return cells;
}

async function create_quiz_for_user(){
    const response = await fetch(app_server + "/quiz/create/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            quiz_type: "user",
            quiz_user_id: extra_data.user_id, 
        }),
    });
    const data = await response.json();
    console.log("Quiz:", data);
    if(data.status == "success"){
        console.log("SUCCESS!!!!");
        router.replace({name:"quiz", params:{quiz_id: data.quiz_id}});
    }
}

async function set_tag(tag){
    const response = await fetch(app_server + "/user/tag/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            tag: tag, 
        }),
    });
    const data = await response.json();
    if(data.status == "success"){
        console.log("Changed tag for user");
        if(userStore.info.user_id == extra_data.user.user_id){
            userStore.info.tag = tag;
            extra_data.user.tag = tag;
        }
    }
}
</script>

<template>
    <Header />
    <div class="container mx-auto" :key="extra_data.user_id">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="p-4 rounded-md bg-case-light-color flex relative">
            <div v-if="extra_data.user.user_id" class="grow">
                <h3 class="text-lg font-medium">
                    <UserName :user="extra_data.user" />
                </h3>
                
                <p class="font-thin">{{ to_readable_date(extra_data.user.date_joined) }}</p>
                <p class="font-thin">{{ extra_data.user.level[2] }}</p>
                <div class="flex flex-wrap gap-x-2">
                    <UserTag 
                        v-for="tag in extra_data.user.tags.split(';')" 
                        :class="{'cursor-pointer hover:brightness-110' : userStore.info.user_id == extra_data.user.user_id}"
                        :tag="tag" 
                        @click="set_tag(tag)"
                    />
                    <UserTag 
                        class="cursor-pointer hover:brightness-110"
                        :tag="'No tag:#AAAAAA'" 
                        @click="set_tag(':')"
                    />
                </div>
                
            </div>
            <div class="w-16 h-16 cursor-pointer"
                 :class="{'border-case-med-color border flex justify-center items-center': !extra_data.user.avatar}">
                <router-link :to="{name:'user-avatar'}">
                    <Avatar :avatar_id="extra_data.user.avatar"/>
                    <span v-if="!extra_data.user.avatar">
                        Avatar
                    </span>
                </router-link>
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.er_table">
                <span class="flex justify-between">
                    <h3 class="text-lg font-medium">Average ER 
                        <span v-if="extra_data.show_er == 1">(Standard deviation)</span>
                        <span v-if="extra_data.show_er == 2">(N)</span>
                    </h3>
                </span>
                <div @click="extra_data.show_er = (extra_data.show_er + 1) % 3" 
                    class="gap-y-1 gap-x-0.5 bg-field-color
                    grid grid-flow-col grid-rows-2 auto-cols-fr align-items-stretch justify-items-stretch">
                    <div class="bg-case-light-color text-center p-2 tabular-nums" 
                        v-for="cell in get_er_table_cells(extra_data.show_er)">
                        <SparklesIcon v-if="cell == 'sparkle'" class="w-6 h-6 inline-block"/>
                        <span v-else>{{ cell }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.insights">
                <span class="flex justify-between">
                    <h3 class="text-lg font-medium">Insights</h3>
                    <ToolTip :text="'Start a quiz built around this player\'s 3 worst categories.'">
                    <button class="btn btn-blue" @click="create_quiz_for_user()">
                        Quiz
                    </button>
                    </ToolTip>
                </span>
                <table class="w-full tabular-nums">
                    <tr>
                        <th class="text-left">Tag</th>
                        <th>ER</th>
                        <th>EQ</th>
                        <th>Count</th>
                    </tr>
                    <tr v-for="value in extra_data.insights.data">
                        <td>
                            <ToolTip :text="extra_data.insights.explanation[value[0]]" :direction="'top'">
                            {{ value[0] }}
                            </ToolTip>
                        </td>
                        <td class="text-right">{{ value[1].ER.toFixed(2) }}</td>
                        <td class="text-right">{{ value[1].eq_lost.toFixed(2) }}</td>
                        <td class="text-right">{{ value[1].count }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <!--
        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.user_info.charts">
                <span class="flex justify-between">
                    <h3 class="text-lg font-medium">Elo: {{ extra_data.user.elo.toFixed(2) }}</h3>
                    <h3 class="text-lg font-thin">exp: {{ extra_data.user.experience }}</h3>
                </span>
                <div class="relative w-full h-full">
                    <Line :data="get_chart_data('elo')" :options="chart_options" />
                </div>
            </div>
        </div>
        -->

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.charts.rating">
                <h3 class="text-lg font-medium">Rating: {{ extra_data.user.rating.toFixed(2) }}</h3>
                <div class="relative w-full h-full">
                    <Line :data="get_chart_data('rating')" :options="chart_options" />
                </div>
                
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.charts.coins">
                <h3 class="text-lg font-medium">Sparkles: {{ extra_data.user.coins }}</h3>
                <div class="relative w-full h-full">
                    <Line :data="get_chart_data('coins')" :options="chart_options" />
                </div>
                
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.charts.er">
                <h3 class="text-lg font-medium">
                    ER: {{ extra_data.user.avg_er.toFixed(2) }} ({{extra_data.user.median_er.toFixed(2)}})
                </h3>
                <div class="relative w-full h-full">
                    <Line :data="get_chart_data('er')" :options="chart_options" />
                </div>
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.charts.coin_er">
                <h3 class="text-lg font-medium">
                    Unlimited ER
                </h3>
                <div class="relative w-full h-full">
                    <Line :data="get_chart_data('coin_er')" :options="chart_options" />
                </div>
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.charts.puzzle_rating">
                <span class="flex justify-between">
                <h3 class="text-lg font-medium">Puzzle Rating: {{ extra_data.user.puzzle_rating.toFixed(2) }}</h3>
                <h3 class="text-lg font-thin">exp: {{ extra_data.user.puzzle_experience }}</h3>
                </span>
                <div class="relative w-full h-full">
                    <Line :data="get_chart_data('puzzle_rating')" :options="chart_options" />
                </div>
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.charts.master_points">
                <span class="flex justify-between">
                <h3 class="text-lg font-medium">Master Points: {{ extra_data.user.master_points.toFixed(0) }}</h3>
                </span>
                <div class="relative w-full h-full">
                    <Line :data="get_chart_data('master_points')" :options="chart_options" />
                </div>
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.tournaments">
                <h3 class="text-lg font-medium">Latest Tournaments</h3>
                <table class="w-full overflow-y-auto max-h-96 overflow-y-auto">
                    <tr>
                        <th class="text-left">Name</th>
                        <th>Rank</th>
                        <th>MP Earned</th>
                    </tr>
                    <tr v-for="tournament, i in extra_data.tournaments" :key="i" 
                        class="w-full hover:bg-field-light-color">
                        <router-link :to="{name: 'tournament-info', params: {tournament_id: tournament.tournament_id}}">
                            <td>{{ tournament.name }}</td>
                        </router-link>
                        <td class="text-right">{{ tournament.rank }}</td>
                        <td class="text-right">{{ tournament.master_points.toFixed(1) }}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.achievements">
                <h3 class="text-xl font-semibold mb-4">Achievements</h3>
                <div class="flex flex-col gap-y-6 max-h-96 overflow-y-auto">
                    <div class="flex justify-between" 
                         v-for="achievement, i in extra_data.achievements.achievements" :key="i">
                        <div class="flex flex-col">
                            <span class="text-lg font-semibold">{{ achievement.name }}</span>
                            <span class="text-base font-thin text-wrap">{{ achievement.description }}</span>
                        </div> 
                        <div class="flex flex-col gap-y-2 mr-4 w-1/3">
                            <div class="flex justify-end items-center text-base">
                                <span class="font-medium">{{ achievement.value.toFixed(0) }}<span class="font-thin"> / {{ achievement.next_level }}</span></span>
                            </div> 
                            <div class="flex gap-x-1 items-center justify-end">
                                <span v-for="j in Array(achievement.level).keys()"
                                      class="h-6 w-6 rounded-full border" 
                                      :style="{'background-color': 
                                                extra_data.achievements.color_keys[achievement.colors[j]],
                                               'border-color': extra_data.achievements.color_keys[achievement.colors[4]]}" />
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.matches">
                <h3 class="text-lg font-medium">Latest Matches</h3>
                <div class="max-h-96 overflow-y-auto">
                    <MatchList :matches='extra_data.matches'/>
                </div>
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.coin_matches">
                <h3 class="text-lg font-medium">Latest Unlimited Matches</h3>
                <div class="max-h-96 overflow-y-auto">
                    <MatchList :matches='extra_data.coin_matches'/>
                </div>
            </div>
        </div>
    
    </div>
    </div>
</template>

<style>
</style>
